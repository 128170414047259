@font-face {
    font-family: 'Futura Book font';
    src: local('Futura Book font'), url(../fonts/FuturaBookfont.ttf) format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Myriad Pro Regular';
    src: local('Myriad Pro Regular'), url(../fonts/MyriadProRegular.ttf) format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Futura Md BT Bold Italic';
    src: local('Futura Md BT Bold Italic'), url(../fonts/FuturaMdBTBoldItalic.ttf) format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Futura Bk BT Book Italic';
    src: local('Futura Bk BT Book Italic'), url(../fonts/FuturaBkBTBookItalic.ttf) format('truetype');
    font-display: swap;
}

html, body {
    height: 100%;
}

.certifications-container {
    font-family: 'Futura Book font';
    min-height: calc(100vh - 330px);
    color: black;
    font-size: 13pt;
    
    .hide {
        display: none;
    }

    .pdf-container {
        display: none;
        font-family: Calibri, 'Times New Roman';
        margin-bottom: 40px; 
        font-size: 8px;

        .smaller-text {
            font-size: 6px;
        }

        table {
            border: 1px solid black; 
            border-collapse: collapse; 
            text-align: center;
            width: 100%;
        }

        h2 {
            text-align: center;
            margin-bottom: 0;
        }

        #pageHeader {
            h3 {
                margin-bottom: 5px;
            }

            h3,
            div {
                font-size: 10px;
                font-weight: bold;
            }

            p, #note {
                font-weight: normal;
                text-align: left;
                font-size: 8px;
            }

            p {
                margin-bottom: 0;
            }

            #note {
                margin-bottom: 5px;
            }
        }

     h3 {
        text-align: center;
        margin-bottom: 0;
     }

    table {   
        
        .category-column {
            width: 5px;
            max-width: 5px;
        }

        .subcategory-column {
            width: 10px;
            max-width: 10px;
        }

        .series-column {
            width: 10px;
            max-width: 10px;
        }

        .dlc-column {
            width: 8px;
            max-width: 8px;
        }

        .es-column {
            width: 5px;
            max-width: 5px;
        }

        .model-column {                
            width: 10px; 
            max-width: 10px;
        }

        tbody {
            tr {

                td {
                    text-align: left;
                }

                .series-column {
                    padding-right: 3px;
                }

                .model-column {      
                    text-align: left;  
                }
            }
        }

            tr {
                th {
                    vertical-align: middle;

                    img {
                        width: 20px;
                        max-width: 20px;
                        height: 20px;
                        max-height: 20px;
                        object-fit: contain;
                    }
                }            
            }
        td, th {
            font-size: 8px; 
            padding: 2px;  
            text-align: center;
            border: 1px solid #dddddd;
            // width: 15px;
        }

        .subcategory-name-row {
            background-color: white !important;
            font-weight: bold;
        } 

        tr {                    
            border-collapse: collapse; 
            break-inside: avoid;

            .model-column {    
                a {
                    color: inherit;
                }

                .soe-indicator-container {
                    background: #8BC43F;
                    padding: 1px 3px;
                    font: 4px/5px "FBold","Helvetica Neue",Helvetica,sans-serif;
                    vertical-align: text-bottom;
                    margin: 0 7px;
        
                    .soe-indicator {
                        font-family: 'Futura Book font';
                        font-weight: bold;
                        letter-spacing: 1px;
                        color: #FFF;
                    }
                }

                .labels-container {
                    position: absolute;

                    .label {
                        position: relative;
                        top: -1px;
                        left: -8px;
                        z-index: 1;
                        display: inline-block;
                        padding: 1px 3px 1px 0;
                        color: #FFF;
                        font: 4px/5px "FBold","Helvetica Neue",Helvetica,sans-serif;
                        text-transform: uppercase;
                        letter-spacing: 0.015em;
                        white-space: nowrap;
        
                        &:before {
                            display: inline-block;
                            position: relative;
                            left: 0;
                            margin-bottom: -7px;
                            content: "";
                            width: 0;
                            height: 0;
                            border-style: solid;
                            border-width: 0 5px 5px 0;
                        }
                    }
        
                    .sale-label {
                        background:  #224ff5 none repeat scroll 0% 0%;
        
                        &:before {
                            border-color: transparent  #092c75 transparent transparent;
                        }
                    }

                    .rfp-label {
                        background:  #726bc2 none repeat scroll 0% 0%;
        
                        &:before {
                            border-color: transparent  #4b4686 transparent transparent;
                        }
                    }
        
                    .out-of-stock-label {
                        background:  #aaa none repeat scroll 0% 0%;
        
                        &:before {
                            border-color: transparent  #6d6d6d transparent transparent;
                        }
                    }
        
                    .new-label {
                        background: #da431d none repeat scroll 0% 0%;
        
                        &:before {
                            border-color: transparent  #902e10 transparent transparent;
                        }
                    }

                    .back_order-label {
                        background: #67151E none repeat scroll 0% 0%;
        
                        &:before {
                            border-color: transparent  #4F030D transparent transparent;
                        }
                    }

                    .mto-label {
                        background: #976bd0 none repeat scroll 0% 0%;
        
                        &:before {
                            border-color: transparent  #6d33b9 transparent transparent;
                        }
                    }
        
        
                    .special_order-label {
                        background: #5e983a none repeat scroll 0% 0%;
        
                        &:before {
                            border-color: transparent  #0d4822 transparent transparent;
                        }
                    }
        
                    .pre_order-label {
                        background: #b9ad51 none repeat scroll 0% 0%;
        
                        &:before {
                            border-color: transparent  #7b7543 transparent transparent;
                        }
                    }
        
                    .clearance-label {
                        background:#44b9f1 none repeat scroll 0% 0%;
        
                        &:before {
                            border-color: transparent  #2c89b5 transparent transparent;
                        }
                    }  
        
                    .promo-label {
                        background: #f5be22 none repeat scroll 0% 0%;
        
                        &:before {
                            border-color: transparent  #b3880d transparent transparent;
                        }
                    }

                    .in_dev-label {
                        background:#97bd2e none repeat scroll 0% 0%;
        
                        &:before {
                            border-color: transparent  #647c21 transparent transparent;
                        }
                    }

                    .internal-label {
                        background:#2e8bbd none repeat scroll 0% 0%;
                    
                        &:before {
                            border-color: transparent  #073f5e transparent transparent;
                        }
                    }

                    .retired-model-label {
                        font-family: 'Futura Book font';
                        font: 4px/5px "FBold","Helvetica Neue",Helvetica,sans-serif;
                        font-weight: bold;
                        letter-spacing: 1px;
                        background: #726658 none repeat scroll 0% 0%;

                        .click {
                            font-family: "Futura Md BT Medium Italic";
                        }

                        .retired-model-font-weight {
                            font-weight: bold;
                        }
        
                        &:before {
                            border-color: transparent  #494746 transparent transparent;
                        }
                    }

                    .transition-label {
                        background: #1379b2 none repeat scroll 0% 0%;
                        font-size: 4px;
        
                        &:before {
                            border-color: transparent  #082e46 transparent transparent;
                        }

                        a {
                            color: #fff;
                        }
        
                        .click {
                            font-family: "Futura Md BT Medium Italic";
                            text-transform: initial;
                            font-size: 10px;
                            font-weight: normal;
                        }
        
                        .transition-model-font-weight {
                            font-weight: bold;
                        }
                    }
        
                    .sale-label,
                    .out-of-stock-label,
                    .new-label,
                    .special_order-label,
                    .pre_order-label,
                    .clearance-label,
                    .promo-label,
                    .mto-label,
                    .transition-label,
                    .in_dev-label,
                    .back_order-label,
                    .internal-label,
                    .rfp-label {
                        font-family: 'Futura Book font';
                        font-weight: bold;
                        letter-spacing: 1px;
                    }
                }
            }

            .qty-column {
                width: 25px;
                max-width: 25px;
            }
        }

        tr:nth-child(even) {
            background-color: #dddddd;
        }
    }

    .indoor-header {
        background-color: #3378de;
    }

    .outdoor-header {
        background-color: #ffff00;
    }

    .canopy-header {
        background-color: #7570cf;
    }

    .retrofit-header {
        background-color: #009933;
    }

    .emergency-header {
        background-color: #ff6600;
    }

    .controls-header {
        background-color: #cc99cc;
    }

    .labels-container .label {                    
        padding-left: 5px;
        padding-right: 5px;
        padding-top: 2px;
        padding-bottom: 2px;
        color: #FFF;
        font-size: 2px;
        text-transform: uppercase;
        letter-spacing: 0.015em;
        white-space: nowrap;
    }

    .labels-container .sale-label {
        background:  #224ff5 none repeat scroll 0% 0%;
    }

    .labels-container .out-of-stock-label {
        background:  #aaa none repeat scroll 0% 0%;
    }

    .labels-container .new-label {
        background: #da431d none repeat scroll 0% 0%;
    }

    .labels-container .mto-label {
        background: #976bd0 none repeat scroll 0% 0%;
    }

    .labels-container .special_order-label {
        background: #5e983a none repeat scroll 0% 0%;
    }

    .labels-container .pre_order-label {
        background: #b9ad51 none repeat scroll 0% 0%;
    }

    .labels-container .clearance-label {
        background:#44b9f1 none repeat scroll 0% 0%;
    }  

    .labels-container .promo-label {
        background: #f5be22 none repeat scroll 0% 0%;
    }

    .labels-container .price_drop-label {
        background: #f26739 none repeat scroll 0% 0%;
    }

    .labels-container .in_dev-label {
        background:#97bd2e none repeat scroll 0% 0%;
    }  

    .labels-container .retired-model-label {
        background: #726658 none repeat scroll 0% 0%;
    }

    .labels-container .transition-label {
        background: #1379b2 none repeat scroll 0% 0%;
    }

     .in-stock-label,
     .sale-label,
     .out-of-stock-label,
     .new-label,
     .special_order-label,
     .pre_order-label,
     .clearance-label,
     .promo-label,
     .price_drop-label,
     .mto-label,
     .transition-label,
     .in_dev-label,
     .retired-model-label {
        font-weight: bold;
        letter-spacing: 1px;
     }
     
     .pageFooter {        
        margin-top: 40px;
        padding-top: 40px;
        font-size: 7px;
        text-align: left;
    }

     #pageHeader {
        font-size: 9px;
        text-align: center;
     }

     .img-container {
        width: 80px;
     }

     .img-container img {
        width: 100%;
        height: auto;
        object-fit: contain;
     }

     .bold {
        font-weight: bold;
     }

     .control-column {
        width: 60px;
        text-align: left;
     }

     table tr th {
        vertical-align: middle;
     }

     tr {
        page-break-before: always;
        page-break-after: always;
        page-break-inside: avoid;
      
        display: inline-table;    
        width: 100%;                
     }

     table {
        word-wrap: break-word;
     }


        .hide {
            visibility: hidden;
        }
    }

    .body-container {
        margin: 0 150px;
        position: relative;

        h3 {
            text-align: left;
            background-color: #104E77;
            color: white;
            letter-spacing: 1px;
            padding: 5px 50px;
            margin-bottom: 0;
            font-weight: unset;
            text-transform: uppercase;
        }

        h3:after {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 23px;
            content: '';
            height: 95px;
            width: 7px;
            border-right: 7px solid white;
            -webkit-transform: skew(-0deg);
            -moz-transform: skew(-0deg);
            transform: skew(-0deg);
            z-index: 1;
        }

        h3:before {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 38px;
            content: '';
            height: 95px;
            width: 7px;
            border-right: 7px solid white;
            -webkit-transform: skew(-0deg);
            -moz-transform: skew(-0deg);
            transform: skew(-0deg);
            z-index: 1;
        }

        &:before {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 8px;
            content: '';
            height: 95px;
            width: 7px;
            border-right: 7px solid white;
            transform: skew(0deg);
            z-index: 1;
        }

        #table-mobile-view {
            display: none;
        }

        #clear-button {
            margin: 10px 0 10px 0;
            text-align: left;
        }

        .download-pdf-button {
            margin: 10px 0;
            text-align: left;
            position: relative;
            z-index: 97;
        }

        .upper-table-container {
            margin: 10px 0 20px 0;
            overflow-x: hidden; /* Hide x-scroll here */
            // transform:rotateX(180deg);
            // -ms-transform:rotateX(180deg); /* IE 9 */
            // -webkit-transform:rotateX(180deg); /* Safari and Chrome */
            z-index: 2;
            position: relative;

            .scroll-wrapper {
                position: relative;
                overflow: hidden;
                margin-bottom: 5px;
        
                &.top-scroll,
                &.bottom-scroll {
                    height: 20px;
                    overflow-x: auto;
                    overflow-y: hidden;
                }
            }

            .table-container {
                // transform:rotateX(180deg);
                // -ms-transform:rotateX(180deg); /* IE 9 */
                // -webkit-transform:rotateX(180deg); /* Safari and Chrome */
                padding: 20px 10px 20px 50px;
                text-align: center;

                overflow-x: auto; /* Ensure this scrolls the content */
                position: relative;

                .hide {
                    display: none;
                }

                .ant-table-pagination.ant-pagination {              
                    z-index: 96;
                    margin-top: 10px !important;
                    width: max-content;
                }

                .ant-table-thead > tr > th.ant-table-column-has-actions {
                    top: 0;
                }

                .ant-table-bordered .ant-table-thead > tr > th {
                    border-right: none;
                    background-color: white;
                    font-weight: bold;
                }

                .ant-table-bordered .ant-table-body > table {
                    border-left: none;
                    border: none;
                }

                .ant-table-bordered .ant-table-tbody > tr > td {
                    border-right: none;
                }

                .ant-table-tbody {
                    tr:last-child {
                        td {
                            border-bottom: none;
                        }
                    }
                }

                .ant-table-body {
                    //overflow-x: auto !important;
                    overflow-x: hidden !important;
                }

                .ant-table-thead > tr:first-child > th:first-child {
                    position: relative;
                    top: 0;
                }

                .ant-table-bordered .ant-table-thead>tr>th {
                    position: relative;
                    top: 0;
                }

                table {
                    //min-width: fit-content !important;
                    width: max-content;
                    border-top: 1px solid #f0f0f0;
                    border-bottom: 1px solid #f0f0f0;
                    margin: 0 auto;

                    .empty-cell {
                        height: 22px;
                    }

                    .mobile-model-header {
                        display: none;
                    }

                    td {
                        padding: 7px 10px !important;
                    }

                    th,
                    .centered-column {
                        text-align: center;
                    }

                    tbody {
                        .table-row-light {
                            background-color: white;
                        }

                        .table-row-dark {
                            background-color: #ececec;
                        }

                        tr {
                            &:hover {
                                cursor: pointer;
                            }
                        }
                    }

                    .strike-through {
                        text-decoration: line-through;
                        text-decoration-color: rgb(219, 29, 29);
                    } 

                    .soe-indicator-container {
                        background: #8BC43F;
                        padding: 3px 7px;
                        font: 8px/10px "FBold","Helvetica Neue",Helvetica,sans-serif;
                        vertical-align: text-bottom;
                        margin: 0 7px;

                        .soe-indicator {
                            font-family: 'Futura Book font';
                            font-weight: bold;
                            letter-spacing: 1px;
                            color: #FFF;
                        }
                    }

                    .price_dropped-label {
                        margin-left: 5px;
                
                        img {
                            width: 55px;
                            height: auto;
                            object-fit: contain;
                        }
                    }

                    .labels-container {
                        position: absolute;
                        top: 0;

                        .label {
                            position: relative;
                            top: -10px;
                            left: -19px;
                            z-index: 1;
                            display: inline-block;
                            padding: 1px 7px 1px 20px;
                            color: #FFF;
                            font: 8px/10px "FBold","Helvetica Neue",Helvetica,sans-serif;
                            text-transform: uppercase;
                            letter-spacing: 0.015em;
                            white-space: nowrap;
            
                            &:before {
                                display: inline-block;
                                position: relative;
                                left: 5px;
                                margin-bottom: -11px;
                                margin-left: -24px;
                                content: "";
                                width: 0;
                                height: 0;
                                border-style: solid;
                                border-width: 0 8px 8px 0;
                            }
                        }
            
                        .sale-label {
                            background:  #224ff5 none repeat scroll 0% 0%;
            
                            &:before {
                                border-color: transparent  #092c75 transparent transparent;
                            }
                        }
            
                        .out-of-stock-label {
                            background:  #aaa none repeat scroll 0% 0%;
            
                            &:before {
                                border-color: transparent  #6d6d6d transparent transparent;
                            }
                        }
            
                        .new-label {
                            background: #da431d none repeat scroll 0% 0%;
            
                            &:before {
                                border-color: transparent  #902e10 transparent transparent;
                            }
                        }

                        .back_order-label {
                            background: #67151E none repeat scroll 0% 0%;
            
                            &:before {
                                border-color: transparent  #4F030D transparent transparent;
                            }
                        }

                        .mto-label {
                            background: #976bd0 none repeat scroll 0% 0%;
            
                            &:before {
                                border-color: transparent  #6d33b9 transparent transparent;
                            }
                        }
            
            
                        .special_order-label {
                            background: #5e983a none repeat scroll 0% 0%;
            
                            &:before {
                                border-color: transparent  #0d4822 transparent transparent;
                            }
                        }
            
                        .pre_order-label {
                            background: #b9ad51 none repeat scroll 0% 0%;
            
                            &:before {
                                border-color: transparent  #7b7543 transparent transparent;
                            }
                        }
            
                        .clearance-label {
                            background:#44b9f1 none repeat scroll 0% 0%;
            
                            &:before {
                                border-color: transparent  #2c89b5 transparent transparent;
                            }
                        }  
            
                        .promo-label {
                            background: #f5be22 none repeat scroll 0% 0%;
            
                            &:before {
                                border-color: transparent  #b3880d transparent transparent;
                            }
                        }

                        .in_dev-label {
                            background:#97bd2e none repeat scroll 0% 0%;
            
                            &:before {
                                border-color: transparent  #647c21 transparent transparent;
                            }
                        }    

                        .retired-model-label {
                            font-family: 'Futura Book font';
                            font: 8px/10px "FBold","Helvetica Neue",Helvetica,sans-serif;
                            font-weight: bold;
                            letter-spacing: 1px;
                            background: #726658 none repeat scroll 0% 0%;

                            .click {
                                font-family: "Futura Md BT Medium Italic";
                            }

                            .retired-model-font-weight {
                                font-weight: bold;
                            }
            
                            &:before {
                                border-color: transparent  #494746 transparent transparent;
                            }
                        }

                        .transition-label {
                            background: #1379b2 none repeat scroll 0% 0%;
                            font-size: 9px;
            
                            &:before {
                                border-color: transparent  #082e46 transparent transparent;
                            }

                            a {
                                color: #fff;
                            }
            
                            .click {
                                font-family: "Futura Md BT Medium Italic";
                                text-transform: initial;
                                font-size: 10px;
                                font-weight: normal;
                            }
            
                            .transition-model-font-weight {
                                font-weight: bold;
                            }
                        }
            
                        .sale-label,
                        .out-of-stock-label,
                        .new-label,
                        .special_order-label,
                        .pre_order-label,
                        .clearance-label,
                        .promo-label,
                        .mto-label,
                        .transition-label,
                        .in_dev-label,
                        .back_order-label {
                            font-family: 'Futura Book font';
                            font-weight: bold;
                            letter-spacing: 1px;
                        }
                    }
                }

                .ant-table.ant-table-bordered > .ant-table-container {
                    border: none;
                }

                .image-container {
                    img {
                        height: 50px;
                        width: 50px;
                        max-height: 50px;
                        min-height: 50px;
                        min-width: 50px;
                        object-fit: contain;
                    }
                }

                th:first-child {
                    text-align: center;
                } 

                td {
                    .image-container {
                        text-align: center;
                    }
                }
            }
        }
    }
}

@media  (max-width: 926px) {  
    .certifications-container {  
        .body-container {
            margin: 0 70px;
        }
    }
}

@media only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px)  {
    .certifications-container {
        .body-container {
            .upper-table-container {            
                overflow-x: unset;            
                transform: none;
                -ms-transform: none;
                -webkit-transform: none;
                position: unset;

                .table-container {
                    overflow-x: unset;      
                    transform: none;
                    -ms-transform: none;
                    -webkit-transform: none;
            
                    table, thead, tbody, th, td, tr { 
                        display: block; 
                    }
                    
                    table {
                        width: auto;

                        .empty-cell {
                            width: 100%;
                            height: 22px;
                        }

                        .mobile-model-header {
                            display: block;
                            width: fit-content;
                        }

                        .desktop-model-header {
                            display: none;
                        }

                        .labels-container {
                            //position: unset;
                            display: block;

                            .label {
                                top: -13px; 
                                //position: unset;
                                //padding: 1px 7px 1px 10px;

                                // &:before {
                                //     display: none;
                                // }
                            }
                        }

                        thead {
                            tr { 
                                th {
                                    display: none;
                                }
                                
                                & th:first-child {
                                    display: block;
                                }
                            }                            
                        }               

                        tbody {
                            tr { 
                                border: 1px solid #ccc; 
                            }

                            td { 
                                border: none;
                                border-bottom: 1px solid #eee; 
                                position: relative;
                                padding-left: 50% !important; 
                            }
                            
                            td:before { 
                                position: absolute;
                                top: 6px;
                                left: 6px;
                                width: 45%; 
                                padding-right: 10px; 
                                white-space: nowrap;
                            }
                            
                            td:nth-of-type(1):before { content: "Category"; }
                            td:nth-of-type(2):before { content: "Sub-Category"; }
                            td:nth-of-type(3):before { content: "Series"; }
                            td:nth-of-type(4):before { content: "Model #"; }
                            td:nth-of-type(5):before { content: "DLC 5.1 ID#"; }
                            td:nth-of-type(6):before { content: "Energy Star ID#"; }
                        }
                    }
                }
            }
        }
    }
}

@media  (max-width: 772px) {  
    .certifications-container {  
        .body-container {
            margin: 0 30px;
        }
    }
}

@media  (max-width: 688px) {  
    .certifications-container {  
        .body-container {
            h3:first-child {
                line-height: unset;
            }
        }
    }
}

@media  (max-width: 425px) { 
    .certifications-container {  
        .body-container {
            margin: 0 15px;

            .upper-table-container {
                .table-container {
                    padding: 5px;
                }
            }
        }
    }
}

@media  (max-width: 374px) {  
    .certifications-container {  
        .body-container {
            font-size: 11pt;

            h3 {
                padding: 3px 20px;
                display: flex;
                align-items: center;
            }

            h3:first-child {
                padding-right: 60px;
                height: 95px;
                line-height: 21px;
            }
        }
    }
}

@media  (max-width: 365px) {  
    .certifications-container {  
        .body-container { 
            h3 {
                padding: 3px 20px;
            }

            h3:first-child {
                line-height: 21px;
            }
        }
    }
}

@media  (max-width: 330px) {  
    .certifications-container {  
        .body-container {
            h3 {
                padding: 3px 5px;
            }
        }
    }
}

@media print {
    .certifications-container {
        .body-container {
            display: none;
        }

        .pdf-container {
            display: block !important;

            #main-body {
                height: 100%;

                table {
                    thead {
                        page-break-after: avoid;
                    }

                    tbody {
                        tr {
                            td {
                                a {
                                    color: inherit;
                                }
                            }
                        }
                    }

                    .labels-container {
    
                        .label {
                            top: -20px;
                        }
                    }
                }
            }
        }
    }
}