@import '~antd/dist/antd.css';
@font-face {
  font-family: 'Futura Book font';
  src: local('Futura Book font'), url(./fonts/FuturaBookfont.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Futura Md BT Bold Italic font';
  src: local('Futura Md BT Bold Italic font'), url(./fonts/FuturaMdBTBoldItalic.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Futura Bk BT Book Italic font';
  src: local('Futura Bk BT Book Italic font'), url(./fonts/FuturaBkBTBookItalic.ttf) format('truetype');
  font-display: swap;
}

html, body {
  height: 100%;
}

.form-message {
  right: 45vw;            
  transform: translate(50%, 50%);
  top: 40vh !important;
  //background-color: #dc8d8de8;
  width: 50vw !important;
}

.auto-logout-notification-container {
  font-family: 'Futura Md BT Bold Italic';

  .ant-modal-content {
    padding-top: 24px;
    border-radius: 10px;

    &:before {
      content: "";
      display: block;
      width: 100%;
      height: 10px;
      background: #f5be22;
      left: 0;
      top: 0;
      position: absolute;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }

    .logout-button-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      button {
        font-family: 'Futura Bk BT Book Italic font';
        font-size: 16px;
        color: white;
        display: flex;
        align-items: center;
        border-width: 1px;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  #title {
    font-family: 'Futura Md BT Bold Italic font';
    font-size: 18px;
  }

  #description {
    font-family: 'Futura Bk BT Book Italic font';
    font-size: 16px;
  }
}

.App {
  text-align: center;
  min-height: 100vh;
  overflow: hidden;
  word-break: break-word;

  .labels-container {
    display: flex;
    flex-wrap: nowrap;   
    width: max-content;

    .sale-label {
        background:  #224ff5 none repeat scroll 0% 0%;

        &:before {
            border-color: transparent  #092c75 transparent transparent;
        }
    }

    .out-of-stock-label {
        background:  #aaa none repeat scroll 0% 0%;

        &:before {
            border-color: transparent  #6d6d6d transparent transparent;
        }
    }

    .new-label {
        background: #da431d none repeat scroll 0% 0%;

        &:before {
            border-color: transparent  #902e10 transparent transparent;
        }
    }

    .back_order-label {
        background: #67151E none repeat scroll 0% 0%;

        &:before {
            border-color: transparent  #4F030D transparent transparent;
        }
    }

    .mto-label {
        background: #976bd0 none repeat scroll 0% 0%;

        &:before {
            border-color: transparent  #6d33b9 transparent transparent;
        }
    }

    .special_order-label {
        background: #5e983a none repeat scroll 0% 0%;

        &:before {
            border-color: transparent  #0d4822 transparent transparent;
        }
    }

    .pre_order-label {
        background: #b9ad51 none repeat scroll 0% 0%;

        &:before {
            border-color: transparent  #7b7543 transparent transparent;
        }
    }

    .coming_soon-label {
        background: #e563d2 none repeat scroll 0% 0%;

        &:before {
            border-color: transparent  #953787 transparent transparent;
        }
    }

    .legacy-label {
      background: #5d5883 none repeat scroll 0% 0%;

      &:before {
          border-color: transparent  #1c1939 transparent transparent;
      }
    }

    .clearance-label {
        background:#44b9f1 none repeat scroll 0% 0%;

        &:before {
            border-color: transparent  #2c89b5 transparent transparent;
        }
    }  

    .promo-label {
        background: #f5be22 none repeat scroll 0% 0%;

        &:before {
            border-color: transparent  #b3880d transparent transparent;
        }
    }

    .in_dev-label {
        background:#97bd2e none repeat scroll 0% 0%;

        &:before {
            border-color: transparent  #647c21 transparent transparent;
        }
    }  

    .internal-label {
        background:#2e8bbd none repeat scroll 0% 0%;
    
        &:before {
            border-color: transparent  #073f5e transparent transparent;
        }
    }  

    .sale-label,
    .out-of-stock-label,
    .new-label,
    .special_order-label,
    .pre_order-label,
    .coming_soon-label,
    .legacy-label,
    .clearance-label,
    .promo-label,
    .mto-label,
    .in_dev-label,
    .back_order-label,
    .internal-label {
        font-family: 'Futura Book font';
        font-weight: bold;
        letter-spacing: 1px;
    }
}

  #upload_widget {
    height: auto;
  }

  // .hide {
  //   display: none !important;
  // }

  .anchor {
    display: block;
    position: relative;
    top: -175px;
    visibility: hidden;
  }
}

.internal-label {
  background:#2e8bbd none repeat scroll 0% 0%;

  &:before {
      border-color: transparent  #073f5e transparent transparent;
  }
}

.inactive-label {
  background:#8d8585 none repeat scroll 0% 0%;

  &:before {
      border-color: transparent  #524c4c transparent transparent;
  }
}

.internal-label,
.inactive-label {
  font-family: 'Futura Book font';
  font-weight: bold;
  letter-spacing: 1px;
}

.ant-breadcrumb {
  font-family: Futura Book font;
  font-size: 10pt;
  text-align: left;
  background: white !important;
  border-bottom: 1px solid #e8e8e8;
  border-top: 1px solid #e8e8e8;
  width: 100%;
  padding: 10px 70px !important;

  flex-wrap: unset !important;
  margin-bottom: 0 !important;
  list-style: none;
  border-radius: unset !important;

  position: fixed;
  z-index: 98;
  top: 130px;
  overflow: hidden;

  .ant-breadcrumb-link {
    color: #104e77;

    a {
      &:hover {
        color: #40a9ff;
      }
    }
  }

  .ant-breadcrumb-separator {
    color: #104e77;
  }

  // span:first-child {
  //   margin-left: 10px;
  // }

  span {
    font-size: 11pt;
  }
}

.ant-back-top { 
  right: 15px;
}

.ant-notification {
  z-index: 9999999 !important;
  top: 32px !important;
}

.ant-notification-notice {
  top: 83px;
}

.ant-layout {
  display: unset;
}

.ant-layout-header {
  height: unset;
  padding: 0; 
  line-height: unset;
  background: unset;
  width: 100%;
}

.ant-layout-content { 
  margin-top: 200px;
}

.ant-layout-footer {
  padding: 0;
  color: unset;
  font-size: unset;
  background: unset;
  width: 100%;
}

.ant-menu-submenu-popup {
  z-index: 999999;
}

.ant-menu-item .anticon {
  min-width: 30px;
}

.ant-menu-vertical .ant-menu-item:not(:last-child) {
  display: inline-block;
  padding-top: 10px;
  margin-top: 8px;
}

.ant-badge-multiple-words {
  padding: 0 5px;
}

.ant-badge-count {
  right: 4px;
  background: #1890ff;
}

.ant-tooltip-inner {
  width: fit-content;
}

.notification-container {
  .ant-btn:not([disabled]):active, .ant-btn:focus {
    color: #104E77;
    border-color: #104E77;
  }

  button {
    font-family: 'Futura Md BT Bold Italic';
    color: #104E77;
    border-color: #104E77;
    border-radius: 0;
    margin-top: 10px;
    display: flex;
    align-items: center;

    &:hover {
      color: #104E77;
      border-color: #104E77;
    }
  }

  i {
    width: 25px; 
    fill: #104e77;
  }
}


.unsupported-browsers-container {
  height: 100vh;
  background-color: #2ba8e0;
  background: linear-gradient(135deg, #2ba8e0 0%, #0054c6 100%);
  background-repeat: no-repeat;
  background-attachment: fixed;
  padding: 20px;

  .page {
    position: relative;
    height: 100%;
  }

  .modal {
    width: 100%;
    background-color: #ffffff;
    border-radius: 4px;
    padding: 2.5em;
    text-align: center;
    box-shadow: 0px 4px 6px 0px rgba(#11435a, 0.25);
    
    @media (min-width: 480px) {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    
    @media (min-width: 680px) {
      width: 640px;
    }
  }

  .modal__message {
    color: #595959;
  }

  .browsers {
    @media (max-width: 480px) {
      max-width: 200px;
      margin: auto;
    }
  }

  .browser {
    margin: 1em;
    vertical-align: top;
    display: inline-block;
    text-decoration: none;
    color: inherit;
  }

  .browser__logo {
    margin-bottom: 0.375em;
    width: 4em;
    height: 4em;
  }

  .modal__message {
    font-size: 1.25em;
  }
}

@media  (max-width: 1024px) {  
  .ant-breadcrumb {
    padding: 10px 30px !important;
  }
}

@media (max-width: 990px) {
  .ant-breadcrumb {
    top: 130px;
  }
}

@media  (max-width: 425px) { 
  .ant-breadcrumb {
    padding: 10px 15px !important;
  }
}

@media print {
  .scroll-banner-text-container,
  .nav-bar-container,
  .ant-breadcrumb,
  .ant-layout-footer {
    display: none !important;
  }

  .ant-layout-content {
    margin-top: 0;
  }
}