html, body {
    height: 100%;
}

.home-container {
    margin-top: -70px;
    background-image: linear-gradient(to bottom right, white, #d3efff);

    min-height: calc(100vh - 259px);

    .clear-button {
        margin: 10px;
    }
    // background: url(https://storage.needpix.com/rsynced_images/garden-2801489_1280.jpg) no-repeat center;
    // background-size: cover;

    // .under-development {   
    //     min-height: calc(100vh - 259px);   
    //     margin: 0 auto;
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    //     resize: both;
    //     overflow: auto;

    //     #subcontainer {
    //         resize: both;
    //         overflow: auto;
    //         color: #2b339c;
    //         border: 1px solid transparent;
    //         border-radius: 10px;
    //         padding: 3em;
    //         background: #f5a322b3;
    //         width: fit-content;
            
    //         h1 {                
    //             color: #2b339c;
    //             font-size: 3em;
    //             margin-bottom: 0;
    //         }

    //         p {
    //             font-size: 1.5em; 
    //             margin-bottom: 0;
    //             font-style: italic;
    //         }
    //     }
    // }

    #home-page-banners {
        .image-container {
            width: 100px;

            img {
                width: 100%;
                height: auto;
                object-fit: contain;
            }
        }
    }

    .resource-upload-form {
        .flex-part-form {
            display: flex;
            flex-direction: row;
            justify-content: center;
            flex-wrap: wrap;
        
            .each-flex-part-form {                
                ::placeholder {
                    font-size: 12.5px;
                }
            
                .ant-form-item { 
                    display: flex;
                    flex-direction: column;
                }
            
                .ant-select-arrow {
                    right: 40px;
                }
            }
        
            .ant-form-item-label .ant-form-item-required {
                left: 0;
            }
        }
    }

    #table-mobile-view {
        display: none;
    }

    .table-container {
        .ant-table-wrapper {
            width: fit-content;
            margin: 0 auto;
        }

        table {
            width: fit-content;
            
    
            thead {
                th {
                    width: fit-content;
                    padding: 5px;
                    text-align: center;
                }
            }
            tr {
                td {
                    max-width: 90vw;
                    width: fit-content;
                    padding: 5px;
    
                    .image-container {
                        margin: 0 auto;
                    }
                }
            }
    
            .file-name-container {
                span {
                    margin-right: 5px;
                }
            }

            .fixed-width {
                width: fit-content;
                max-width: 350px;
            }

            .align-center {
                text-align: center;
            }
        }
    }

    .carousel-container {

        .banner1-bg {
            background: url('https://eelighting-res.cloudinary.com/image/upload/eelighting/creative-assets/eel-assets/home-page-banners/BANNER_-EEL-Hero-01B---R210226-1610.webp') no-repeat center;
            background-size: cover;
            
        }

        .banner2-bg {
            background: url('https://eelighting-res.cloudinary.com/image/upload/eelighting/creative-assets/eel-assets/home-page-banners/BANNER_-EEL-Hero-02B---R210226-1640.webp') no-repeat center;
            background-size: cover;
            
        }

            .image-container {
                width: 60%;
                margin: 0 auto;
                padding: 6em 0;

                img {
                    width: 100%;
                    height: auto;
                }
            }

            .spring-promo {
                width: 100%;
                padding: 0;
            }
        

        .react-multiple-carousel__arrow {
            z-index: 98;
        }

        .each-category-container {
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            flex-wrap: wrap;
            margin: 0 70px;
        }

        .image-container {
            img {
                width: 100%;
                height: auto;
                object-fit: contain;
            }

            // &:hover {
            //     filter: brightness(0.8);
            // }
        }
    }

    .small-buttons-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        margin: 30px 15px;

        .image-container {
            width: 15%;
            margin-right: 4em;

            &:hover {
                cursor: pointer;
            }

            img {
                width: 100%;
                height: auto;
            }
        }

        .image-container:last-of-type {
            margin-right: 0;
        }
    }

    .categories-container {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        flex-wrap: wrap;
        margin: 30px 15px;
    
        .each-card {
            width: 150px;
            -webkit-box-shadow: 10px 10px 5px 0px rgba(221,221,221,1);
            -moz-box-shadow: 10px 10px 5px 0px rgba(221,221,221,1);
            box-shadow: 10px 10px 5px 0px rgba(221,221,221,1);
            border: 1px solid rgba(0, 0, 0, 0.3);
            margin-bottom: 10px;

            img {
                width: 100%;
                height: auto;
            }
    
            &:hover {
                -webkit-box-shadow: 10px 10px 5px 0px #c8def0;
                -moz-box-shadow: 10px 10px 5px 0px #c8def0;
                box-shadow: 10px 10px 5px 0px #c8def0;
            }
    
            .ant-card-meta-title {
                text-overflow: unset;
                white-space: normal;
            }

            .ant-card-body {
                padding: 5px;
            }
        }
    }

    .introduction-container {
        margin: 30px 70px !important;
        text-align: justify;
        
            img {
                width: 100%;
                height: auto;
            }

            #product-features {                
                margin-right: 15px;
                padding: 20px !important;
                background: rgba(0, 0, 0, 0.1);
                border-radius: 10px;

                div {
                    position: relative;
                }

                h2 {
                    position: absolute;
                    top: 50%;  
                    left: 50%; 
                    transform: translate(-50%, -50%);
                    color: white;
                    text-align: center;
                    border: 1px solid transparent;
                    border-radius: 10px;
                    background-color: rgba(0, 0, 0, 0.7);
                    margin-bottom: 0;
                }

                h3 {
                    margin-top: 15px;
                    text-indent: 15px;
                    text-align: center;
                    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,90,255,1) 100%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }

                p {
                    text-indent: 15px;
                }

                img {
                    width: 100%;
                    height: auto;
                    min-height: 38vh;
                    
                    &:hover {
                        filter: brightness(0.8);
                    }
                }
            }             

        #promo-banner-container {            
            margin-right: 15px;
            padding: 20px !important;
            background: rgba(0, 0, 0, 0.1);
            border-radius: 10px;

            a {
                img {
                    width: 100%;
                    min-height: 35vh;
                }
            }

            &:hover {
                filter: brightness(0.8);
            }
        }

        .who-we-are {            
            margin-right: 15px;
            padding: 20px !important;
            text-indent: 15px;
            background: rgba(0, 0, 0, 0.1);
            border-radius: 10px;

            h3 {
                text-align: center;
                background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,90,255,1) 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }

            p {
                margin-bottom: 0;
            }
        }

        .intro-video {
            margin-top: 15px;
        }

        & .introduction-row:last-child {
            margin-right: 0;
        }

        & .introduction-row:first-child {
            align-self: flex-start;
        }
    }

    .our-services {
        margin: 0 70px;

        h2 {
            text-align: center;
            background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,90,255,1) 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        .our-services-rows {
            text-align: justify;
            margin: 10px 20px;


            div {
                margin: 0 auto;
                margin: 0 !important;

                p {
                    background: rgba(0, 0, 0, 0.1);
                    border-radius: 10px;
                    padding: 20px;
                    text-indent: 15px;
                    margin-right: 0;
                    margin-bottom: 0;
                    display: inline-block;
                }
            }
        }
    }
}

@media only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px)  {  
    .home-container {
        #table-mobile-view {
            display: block !important;
            color: darkred;
            text-align: left;
        }

        #home-page-banners {
            td {
                .image-container {
                    width: fit-content;
                    margin: 0;

                    a {
                        img {
                            width: 100%;
                            height: auto;
                            max-height: 70px;
                            min-height: 70px;
                            min-width: unset;
                            object-fit: contain;
                        }
                    }                   
                }
            }

            table, thead, tbody, th, td, tr { 
                display: block; 
            }

            table {
                width: 100%;
                min-width: 100%;

                thead {
                    tr { 
                        th {
                            display: none;
                        }
                        
                        & th:first-child {
                            display: block;
                        }
                    }   
                    
                    tr:first-of-type {
                        display: none;
                    }
                }               

                tbody {
                    tr { 
                        border: 1px solid #ccc; 

                        .product-name {
                            width: 100%;
                        }
                    }

                    td { 
                        border: none;
                        border-bottom: 1px solid #eee; 
                        position: relative;
                        padding-left: 50% !important; 
                        min-height: 33px;
                    }
                    
                    td:before { 
                        position: absolute;
                        top: 6px;
                        left: 6px;
                        width: 45%; 
                        padding-right: 10px; 
                        white-space: nowrap;
                    }                    
                }
            }

            td:nth-of-type(1):before { content: "Image"; }
            td:nth-of-type(2):before { content: "Banner name"; }
            td:nth-of-type(3):before { content: "Linked to"; }
            td:nth-of-type(4):before { content: "Order"; }
            td:nth-of-type(5):before { content: "Action"; }
        }
    }
}

@media  (max-width: 1024px) {  
    .home-container {
        .carousel-container {
            .each-category-container {
                margin: 0 30px;
            }
        }

        .introduction-container {
            margin: 30px !important;
        }

        .our-services {
            margin: 0 30px;
        }
    }
}

@media  (max-width: 991px) { 
    .home-container {
        .introduction-container {         
            #product-features {                
                margin-bottom: 10px;
                margin-right: 0;     
            }

            #promo-banner-container {                
                margin-bottom: 10px;
                margin-right: 0;     
            }
    
            .who-we-are {            
                margin-bottom: 10px;
                margin-right: 0;     
            }
        }        
    }
}

@media  (max-width: 768px) { 
    .home-container {
        .under-development {
            #subcontainer {
                padding: 2em;
            }
        }

        .small-buttons-container {
            margin: 0 15px 30px 15px;

            .image-container {
                width: 18%;
            }
        }
    }
}

@media  (max-width: 629px) { 
    .home-container {
        .small-buttons-container {
            flex-direction: column;
            margin-left: 50px;

            .image-container {
                width: 26%;
                margin-bottom: 2em;
            }

            & :last-child {
                margin-bottom: 0;
            }
        }
    }
}

@media  (max-width: 425px) {  
    .home-container {
        .carousel-container {
            .banner1-bg,
            .banner2-bg {
                background: none;
                margin-left: 13px;
            }

            .image-container {
                width: 100%;
                padding: 0;
            }

            .each-category-container {
                margin: 0 15px;
            }
        }

        .introduction-container {
            margin: 15px !important;
        }

        .our-services {
            margin: 0 15px;
        }

        .under-development {
            #subcontainer {
                padding: 1em;   

                h1 {                
                    font-size: 2.2em;
                }
    
                p {
                    font-size: 1.2em; 
                }
            }
        }

        .small-buttons-container {
            align-items: center;
            margin: 0 0 30px 0;

            .image-container {
                margin-right: 0;
                width: 35%;
            }
        }
    }
}

@media  (max-width: 375px) {  
    .home-container {
        .carousel-container {
            .image-container {
                width: 100%;
            }
        }
    }
}