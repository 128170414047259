.main {
    min-height: calc(100vh - 412px);
  
    .loading-container {
      font-size: 20px;
  
      svg {
        font-size: 25px;
      }
    }
    .back-to-product-page-link {
      border: 2px solid #104e77;
      border-radius: 15px;
      padding: 2px 10px;
      width: fit-content;
      margin: 0 auto;
  
      &:hover {
        background-color: #b1bccc;
      }
  
      a {
        color: #104e77;
      }
    }
  
    .react-photo-gallery--gallery {
      margin: 0 auto;

      div {
          justify-content: center;
      }
    }

    .gallery-container {
  
        img {
          object-fit: cover;
          width: 500px;
          height: 350px;
          padding: 10px;
        }
      
    }
  }